import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ArticleCard from '@/components/Cards/ArticleCard.vue'
import { Matrix, Quadrant, Metric, IItem, Content } from '@/Models'
import http from '@/services'
import ExpandableButton from '@/components/ExpandableButton/index.vue'
import authModule from '@/store/auth'
import articleModule from '@/store/moduleArticle'
import ModuleCMS from '@/store/moduleCMS'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import { ArticleEndPoint } from '@/Models/ArticleEndPoint'
import { Article } from '@/Models/Article'
import menuModule from '@/store/menu'
import AnimationCMS from '@/components/AnimationCMS/index.vue'
import matrixModule from '@/store/matrix'
import moduleCMS from '@/store/moduleCMS'

@Component({
  components: {
    ArticleCard,
    ExpandableButton,
    ModalDelete,
    AnimationCMS,
  },
})
export default class CMS extends Vue {
  matrix_id = -1
  metricProp!: Metric
  strategy = ''
  open = true
  quadrantId = -1
  delete_article_active = false
  objEdit: Article = new Article()
  selected_quadrant = false
  action = 'dashboard'
  user_id = 0
  quadrant = ''
  delete_modal_active = false
  matrix: Matrix[] = []
  matrixSelected: Matrix | null | undefined = null
  matrixList: Array<IItem> = []
  strategies: [] = []
  articles: ArticleEndPoint[] = []
  article: ArticleEndPoint | any = null
  isAdmin = false

  async mounted() {
    menuModule.setLoding(true)
    try {
      this.matrix_id = matrixModule.matrix?.id as number
      const roles = authModule.user.groups.map((x: any) => x.name)
      //por cada rol autorizado
      this.isAdmin = roles.includes('MEC')
      // if (
      //     this.quadrantId != -1 &&
      //     ModuleCMS.quadrantSelected &&
      //     ModuleCMS.quadrantSelected.length > 0
      // ) {
      //   this.setQuadrandId(ModuleCMS.quadrantSelected[0].quadrant)
      // }

      this.user_id = authModule.user.user_id

      await this.getAllMatrix()
      if (this.matrix_id > -1) {
        this.matrixSelected = this.matrix.find(
          (m: any) => m.id == this.matrix_id,
        )
        this.quadrant = matrixModule.quadrantName as string
        this.quadrantId = moduleCMS.quadrantId as number
        if (this.quadrantId > -1) {
          await this.get_articles(
            ModuleCMS.quadrantSelected,
            articleModule.quadrant_content_id,
          )
        }
      }
    } catch (err) {
      console.log(err)
      this.$router.go(-1)
    }

    menuModule.setLoding(false)
  }

  get selectedQuadrantContent() {
    return articleModule.quadrant_content_id
  }
  setQuadrandId(quadrantId: number) {
    this.quadrantId = quadrantId
  }

  saveMatrixSelected() {
    matrixModule.setMatrix(this.matrixSelected)
  }

  setQuadrantName(name: string) {
    matrixModule.setQuadrantName(name)
  }

  async getAllMatrix() {
    try {
      const res = await http.get(`hoshin/matrix/`)
      this.matrix = res.data
    } catch (error) {
      console.log(error)
    }
  }

  get quadants() {
    return (cardinal_point: string): Content[] => {
      if (!this.matrixSelected) return []
      const list: Content[] = (this.matrixSelected as any)[`${cardinal_point}`]
        .content
      return list as Array<Content>
    }
  }

  @Watch('matrixSelected')
  changeMatrixSelected() {
    this.matrix_id = this.matrixSelected?.id as number
    matrixModule.setMatrix(this.matrixSelected)
    this.selected_quadrant = false
    this.articles = []
  }

  get strategies_selected() {
    return this.strategies
      .filter((x: any) => x.matrix.id == this.matrixSelected?.id)
      .map((x: any) => x.strategy)
  }
  async getMatrix() {
    try {
      const res = await http.get(
        `panel/employee_matrix/?user_id=${this.user_id}`,
      )
      this.strategies = res.data
      const data = {} as any
      this.strategies
        .map((x: any) => x.matrix)
        .forEach((m: any) => {
          data[`${m.id}`] = m
        })
      this.matrixList = Object.values(data)
    } catch (error) {
      console.log(error)
    }
  }
  showArticle(article: Article) {
    articleModule.setArticleEndPoint(null)
    this.$router
      .push({
        path: '/article',
      })
      .catch(() => console.log)
  }

  closeModal(valor: boolean) {
    this.delete_modal_active = valor
  }

  showPagesMenu() {
    if (this.action == 'pagesMenu') {
      this.action = 'dashboard'
    } else {
      this.action = 'pagesMenu'
    }
  }

  async confirm_delete() {
    try {
      await http.delete(`cms/article_cms/${this.article.id}/`)
      this.articles = this.articles.filter(
        (a: ArticleEndPoint) => a.id !== this.article.id,
      )
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
    this.delete_modal_active = false
  }

  deleteArticle(article: ArticleEndPoint) {
    this.article = article
    this.delete_modal_active = true
  }

  showDashboard() {
    this.action = 'dashboard'
  }

  async get_articles(quadrant: Quadrant[], quadrant_content_id: number) {
    ModuleCMS.setQuadrantSelected(quadrant)
    ModuleCMS.setQuadrantId(quadrant_content_id)
    console.log(quadrant_content_id, 'content')
    try {
      articleModule.setQuadrantContentId(quadrant_content_id)
      const res = await http.get(
        `cms/articles_by_type_primary_cms/?quadrant_content_id=${quadrant_content_id}`,
      )
      this.articles = res.data

      this.selected_quadrant = true
    } catch (error: any) {
      articleModule.setQuadrantContentId(-1)
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }
  close() {
    this.$emit('closeForm')
  }

  updateArticle(article: ArticleEndPoint) {
    articleModule.setArticleEndPoint(article)
    this.$router
      .push({
        path: '/article',
      })
      .catch(() => console.log)
  }
}
